export function truncateString(string, num) {
  if (!string) {
    return null
  }
  if (string.length >= num) {
    return `${string.slice(0, num)} ...`
  }

  return string
}

export function themeBgColor(theme) {
  switch (theme) {
    case "white":
      return "bg-white"
    case "black":
      return "bg-black-100"
    case "light-lime":
      return "bg-campaign-light-lime-100"
    case "yellow":
      return "bg-campaign-yellow-100"
    case "cyan":
      return "bg-dark-cyan-100"
    case "light-cyan":
      return "bg-dark-cyan-75"
    case "green":
      return "bg-green-50"
  }

  return theme
}

export function themeAccentTextColor(theme) {
  switch (theme) {
    case "green":
      return "text-green-50"
    case "dark-green":
      return "text-green-100"
    case "light-yellow":
      return "text-light-green"
    case "yellow":
      return "text-campaign-yellow-100"
    case "light-lime":
      return "text-light-lime-100"
    case "light-cyan":
      return "text-cyan-75"
  }

  return theme
}

export function themePlateColor(theme, defaultClass = "bg-summer-green-50") {
  switch (theme) {
    case "white":
      return "bg-white"
    case "lime":
      return "bg-summer-green-50"
    case "green":
      return "bg-green-50"
    case "dark-green":
      return "bg-green-100"
    case "yellow":
      return "bg-campaign-yellow-100"
    case "light-lime":
      return "bg-campaign-light-lime-100"
    case "light-cyan":
      return "bg-dark-cyan-75"
    case "transparent":
      return "bg-transparent"
  }
  return defaultClass
}

export function themeTextColor(theme, defaultClass = "text-black-100") {
  switch (theme) {
    case "ink":
      return "text-black-100"
    case "white":
      return "text-white"
  }
  return defaultClass
}

export function formatNumber(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export const headerOffsetScroll = (ref) => {
  if (!ref?.current) {
    return
  }

  const siteHeader = document.querySelector("header")
  const headerHeight = siteHeader.clientHeight
  const offsetTop =
    ref.current.getBoundingClientRect().top + window.pageYOffset - headerHeight

  ref.current.focus()
  window.scrollTo({ top: offsetTop, behavior: "smooth" })
}

export const decodeHTMLEntities = (str) => {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec)
  })
}

export const shuffleArray = (array) => {
  let index = array.length,
    randIndex

  while (index != 0) {
    randIndex = Math.floor(Math.random() * index)
    index--
    ;[array[index], array[randIndex]] = [array[randIndex], array[index]]
  }

  return array
}
