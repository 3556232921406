import Blocks from "@/components/Blocks"
import TextImageBlock from "@/common/partials/textImageBlock/textImageBlock"

export default function ContentBlock({ block }) {
  const { attrs, innerBlocks } = block

  if (innerBlocks === undefined || innerBlocks.length === 0) {
    return null
  }

  const blockSettings = {
    "core/heading": { width: "bleed" },
    "core/paragraph": { width: "bleed" },
    "core/list": { width: "bleed" }
  }

  return (
    <TextImageBlock
      layout={block.attrs.layout}
      image={block.attrs.image}
      hasPlate={attrs?.theme !== "transparent"}
      plateColor={attrs?.theme}
      padding={attrs?.theme === "on-plate"}>
      <Blocks blocks={innerBlocks} settings={blockSettings} />
    </TextImageBlock>
  )
}
